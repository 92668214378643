/* BEGIN Light */
@font-face {
  font-family: "Lato";
  src: url("../fonts/Light/Lato-Light.eot?v=1.0.0");
  src: url("../fonts/Light/Lato-Light.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../fonts/Light/Lato-Light.woff2?v=1.0.0") format("woff2"), url("../fonts/Light/Lato-Light.woff?v=1.0.0") format("woff"), url("../fonts/Light/Lato-Light.ttf?v=1.0.0") format("truetype"), url("../fonts/Light/Lato-Light.svg?v=1.0.0#LatoLight") format("svg");
  font-weight: 300;
  font-style: normal; }
/* END Light */

/* BEGIN Regular */
@font-face {
  font-family: "Lato";
  src: url("../fonts/Regular/Lato-Regular.eot?v=1.0.0");
  src: url("../fonts/Regular/Lato-Regular.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../fonts/Regular/Lato-Regular.woff2?v=1.0.0") format("woff2"), url("../fonts/Regular/Lato-Regular.woff?v=1.0.0") format("woff"), url("../fonts/Regular/Lato-Regular.ttf?v=1.0.0") format("truetype"), url("../fonts/Regular/Lato-Regular.svg?v=1.0.0#LatoRegular") format("svg");
  font-weight: 400;
  font-style: normal; }
/* END Regular */

/* BEGIN Bold */
@font-face {
  font-family: "Lato";
  src: url("../fonts/Bold/Lato-Bold.eot?v=1.0.0");
  src: url("../fonts/Bold/Lato-Bold.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../fonts/Bold/Lato-Bold.woff2?v=1.0.0") format("woff2"), url("../fonts/Bold/Lato-Bold.woff?v=1.0.0") format("woff"), url("../fonts/Bold/Lato-Bold.ttf?v=1.0.0") format("truetype"), url("../fonts/Bold/Lato-Bold.svg?v=1.0.0#LatoBold") format("svg");
  font-weight: 700;
  font-style: normal; }
/* END Bold */

/* BEGIN Black */
@font-face {
  font-family: "Lato";
  src: url("../fonts/Black/Lato-Black.eot?v=1.0.0");
  src: url("../fonts/Black/Lato-Black.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("../fonts/Black/Lato-Black.woff2?v=1.0.0") format("woff2"), url("../fonts/Black/Lato-Black.woff?v=1.0.0") format("woff"), url("../fonts/Black/Lato-Black.ttf?v=1.0.0") format("truetype"), url("../fonts/Black/Lato-Black.svg?v=1.0.0#LatoBlack") format("svg");
  font-weight: 900;
  font-style: normal; }
/* END Black */

/* Crimson */
@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,500;0,700;1,500;1,700&display=swap');
