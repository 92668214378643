.blog-content-section {
  position: relative;
  padding: 0 3.5%;
  a{
    text-decoration: none;
  }
  .blog-content-section_content-wrapper {
    padding: 0 0 2rem;
    background-color: $white;
    box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
    float: left;
    margin-bottom: 3rem;

    //h1{
    //  padding: 0 2rem 1rem;
    //}
    .view-blog-listing{
      .views-row{
        display: block;
        float: left;
        position: relative;
        padding-bottom: 2rem;
        .blog-listing-heading{
          clear: both;
        }
        .blog-listing-image-wrapper {
          padding: 0 2rem;
          float: left;
          width:33.33%;
          @include medium{
            width:25%
          }
        }
        .blog-listing-read-more-button{
          float: right;
          padding: 0 2rem;
        }
      }
      .views-row:before{
        content: '';
        width: calc(100% - 4rem);
        height: 2px;
        background-color: $brand-main;
        padding: 0;
        display: block;
        position: absolute;
        margin: 0 0 0 2rem;
        top: 0;
      }
      .views-row:nth-child(1):before{
        content: none;
      }
    }
  }
  .blog-sidebar-section_content-wrapper {
    position: relative;
    float: right;
    padding: 1rem 0 3rem 0;
    width: 100%;
    @include large {
      width: 30%;
    }
    .blog-sidebar_content {
      .block{
        background-color: $white;
        box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
      }
      header {
        background-color: $brand-main;
        margin-bottom: .5rem;
        h2 {
          color: $white;
          padding: 1rem 2rem;
          margin: 0;
        }
      }
      .block{
        margin: 0 0 2rem;
        padding-bottom: 1rem;
      }
      .blog-tag-listing-wrapper{
        header{
          background-color: transparent;
          margin: 0;
          h3{
            color: $dk-grey;
            padding: 1rem 2rem;
            margin: 0;
            position: relative;
          }
          h3:after{
            content: '';
            display: block;
            width: calc(100% - 4rem);
            background-color: $dk-grey;
            height: 2px;
            position: absolute;
          }
        }
        p{
          margin: 0;
        }
      }
      .blog-filter-wrapper{
        .views-exposed-form .views-exposed-widget{
          padding: 0 2rem;
          width: 100%;
        }
        header{
          background-color: transparent;
          margin: 0;
          h3{
            color: $dk-grey;
            padding: 1rem 2rem;
            margin: 0;
            position: relative;
          }
          h3:after{
            content: '';
            display: block;
            width: calc(100% - 4rem);
            background-color: $dk-grey;
            height: 2px;
            position: absolute;
          }
        }
        p{
          margin: 0;
        }
        span{
          padding: 0 2rem;
          display: block;
          a{
            font-style: normal;
            font-weight: 300;
            line-height: 1.4;
            font-size: 1rem;
            margin: 0 0 1rem;

          }
        }
        a.fieldset-title{
          padding: 0;
        }
        fieldset{
          padding: 0;
          margin: 0;
          border: none;
          background-color:$ex-lt-grey;
          legend{
            background-color: $lt-grey;
            width: 100%;
            padding: .25rem 0;
            span{
              a{
                //color: $white;
              }
            }
          }
          .fieldset-wrapper{
            padding: 0.5rem 1rem;
            label{
              font-style: normal;
              font-weight: 300;
              line-height: 1.4;
              font-size: 1rem;
            }
          }
        }
        .view-content{
          padding: 1rem 0 0;
        }
      }
      form.form-custom {
        display: block;
        padding: 0 2rem 1rem;
        @include large {
          padding: 0 2rem 1rem;
        }
        .input-wrapper {
          width: 100%;
        }
        .center-button-wrapper {
          margin-top: .5rem;
          button {
            margin: 0 !important;
            font-size: 1.25rem;
            text-transform: uppercase;
          }
        }

      }
      .webform-client-form{
        display: block;
        padding: 0 2rem 1rem;
        @include large {
          padding: 0 2rem 1rem;
        }
        .input-wrapper {
          width: 100%;
        }
        button {
          display: block;
          margin:  .5rem auto 0 !important;
          font-size: 1.25rem;
          text-transform: uppercase;
        }
        .form-actions {
          margin-top: .5rem;
          input[type="submit"]{
            display: block;
            margin:  .8rem auto 0 !important;
            font-size: 1.25rem;
            text-transform: uppercase;
          }
        }
      }

    }

  }
}
.page-blog{
  .blog-content-section_content-wrapper{
    margin-top: 1rem;
  }
}

.blog-contact-us-section {
  display: block;
  @include large {
    display: none;

  }
  .contact-us-section_container {
    .contact-us-section_header {
      background-color: $brand-main;
      h1 {
        color: $white;
      }
    }
    form.form-custom {
      display: block;
      background-color: $white;
      padding: 2rem;
      margin-bottom: 3rem;
      .form-row:nth-child(3) {
        padding-bottom: 0;
      }
      .center-button-wrapper {
        margin-top: .5rem;
        button {
          margin: 0 !important;
          font-size: 1.25rem;
          text-transform: uppercase;
        }
      }
    }
    .webform-client-form{
      display: block;
      padding: 0 2rem 1rem;
      @include large {
        padding: 0 2rem 1rem;
      }
      .input-wrapper {
        width: 100%;
      }
      button {
        display: block;
        margin:  .5rem auto 0 !important;
        font-size: 1.25rem;
        text-transform: uppercase;
      }
      .form-actions {
        margin-top: .5rem;
        input[type="submit"]{
          margin: 0 !important;
          font-size: 1.25rem;
          text-transform: uppercase;
        }
      }
    }

  }

}

.page-taxonomy-term{
  .term-listing-heading:after {
    content: '';
    width: calc(100% - 4rem);
    height: 2px;
    background-color: $brand-main;
    padding: 0;
    display: block;
    margin: 0 0 1rem 2rem;
    top: 0;
  }
  .blog-content-section_content-wrapper{
    margin-top: 1rem;
    .blog-entry-wrapper{
      .field-name-field-blog-image {
        padding: 0 2rem;
        float: left;
        width:33.33%;
        clear: both;
        @include medium{
          width:25%
        }
      }
      .tabs{
        display: none;
      }
      .title{
        font-size: 2rem;
        padding: 0 0 0 2rem;
        margin-top: 0;
        line-height: 1;
      }
      .field-name-body{
      }
      .field-name-field-blog-link{
        text-align: right;
        padding: 0 2rem 1rem;
      }
    }
    .blog-entry-wrapper:after {
      content: '';
      width: calc(100% - 4rem);
      height: 2px;
      background-color: $brand-main;
      padding: 0;
      display: block;
      margin: 0 0 1rem 2rem;
      top: 0;
    }
    .blog-entry-wrapper:last-child:after{
      display: none;
    }

  }

}