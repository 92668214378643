//Start How We Help Section
.how-we-help-section {
  position: relative;
  margin-top: 2rem;
  &.home-page {
    margin-top: -2rem;
    @include medium {
      margin-top: -5rem
    }
  }

  .container {
    .how-we-help-section-header {
      padding: 1rem 0;
    }
    .how-we-help-outer-wrapper {
      .how-we-help-inner-wrapper {
        position: relative;
        float: left;
        padding: 0;
        .icon-wrapper {
          text-align: center;
          height: 110px;
          width: 110px;
          margin: 0 auto;
          background: transparent;
          -moz-border-radius: 100%;
          -webkit-border-radius: 100%;
          border-radius: 100%;
          border: 1px solid $brand-main;
        }
        #guide-icon {
          background: url("../img/guide-icon.png") no-repeat;
          background-size: cover;
          transition: all .25s ease-in-out;

        }
        #guide-icon:hover {
          background: url("../img/guide-icon-hover.png") no-repeat;
          background-size: cover;
          background-color: $brand-main;
          box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
        }
        #protect-icon {
          background: url("../img/protect-icon.png") no-repeat;
          background-size: cover;
          transition: all .25s ease-in-out;

        }
        #protect-icon:hover {
          background: url("../img/protect-icon-hover.png") no-repeat;
          background-size: cover;
          background-color: $brand-main;
          box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
        }
        #advocate-icon {
          background: url("../img/advocate-icon.png") no-repeat;
          background-size: cover;
          transition: all .25s ease-in-out;

        }
        #advocate-icon:hover {
          background: url("../img/advocate-icon-hover.png") no-repeat;
          background-size: cover;
          background-color: $brand-main;
          box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
        }
        .text-wrapper {
          text-align: center;
          @include large {
            padding-bottom: 0;
          }
          h2 {
            text-transform: uppercase;
          }

        }

      }
      .how-we-help-inner-wrapper:after {
        display: block;
        content: '';
        background: $lt-grey;
        width: 90%;
        height: 1px;
        top: 100%;
        left: 5%;
        position: absolute;
        @include large {
          display: none;

        }
      }
      .how-we-help-inner-wrapper:nth-child(3):after {
        display: none;
        padding: 1rem 0;
      }
      .how-we-help-inner-wrapper:nth-child(1) {
        padding: 1rem 0;
        @include large {
          padding: 0;
        }
      }
      .how-we-help-inner-wrapper:nth-child(2) {
        padding: 1rem 0;
        @include large {
          padding: 0;
        }
      }
      .how-we-help-inner-wrapper:nth-child(3) {
        padding: 1rem 0 0;
        @include large {
          padding: 0;
        }
      }
    }
  }
}
//End How We Help Section
