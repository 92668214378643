//Start Testimonial Section
.testimonials-section {
  display: block;
  .testimonials-wrapper{
    display: block;
    .testimonial-slide{
      padding: 0 2em;
        width: 90%;
        margin: 0 auto;
        p{
          font-style: italic !important;
        }
    }
    .slick-arrow{
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: 50%;
      display: block;
      width: 40px;
      height: 40px;
      margin-top: -20px;
      padding: 0;
      cursor: pointer;
      color: transparent;
      border: none;
      outline: none;
      background: transparent;
      z-index: 1;
    }
    .slick-prev{
       left:1rem
     }
    .slick-next{
      right:1rem
    }
    .slick-prev:before{
      content: "\f124";
      display: inline-block;
      font-family: "Ionicons";
      font-size: 2rem;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      text-rendering: auto;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: $brand-main;
    }
    .slick-next:before{
      content: "\f125";
      display: inline-block;
      font-family: "Ionicons";
      font-size: 2rem;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      text-rendering: auto;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: $brand-main;
    }

  }
}
//End Testimonial Section
