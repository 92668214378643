//Start Contact Us Section - Homepage
.contact-us-section{
  .container{
  form.form-custom{
    display: block;
    background-color: $white;
    padding: 0 2rem;
    input,textarea{
      margin: 0 0 1.5rem !important;
      font-weight: 300;
    }
    button{
      margin: 0 !important;
      font-size: 1.25rem;
      text-transform: uppercase;
    }
    .form-row:nth-child(3){
      padding-bottom: 0;
    }
    .center-button-wrapper{
      //padding-top: 2rem;
    }
  }

    form.webform-client-form{
      display: block;
      background-color: $white;
      padding: 0 2rem;
      input,textarea{
        margin: 0 0 1.5rem !important;
        font-weight: 300;
      }
      input[type="submit"]{
        margin: .8rem auto 0 !important;
        display: block;
        font-size: 1.25rem;
        text-transform: uppercase;
        font-weight: 400;
      }
      .form-row:nth-child(3){
        padding-bottom: 0;
      }
      .center-button-wrapper{
        //padding-top: 2rem;
      }
    }
  }

}
//End Contact Us Section - Homepage