//Start Header Section
.main-header-wrapper{
  position: relative;
  z-index: 99;
  .top-nav{
    display: -webkit-box; /* OLD: Safari,  iOS, Android browser, older WebKit browsers.  */
    display: -moz-box; /* OLD: Firefox (buggy) */
    display: -ms-flexbox; /* MID: IE 10 */
    display: -webkit-flex; /* NEW, Chrome 21–28, Safari 6.1+ */
    display: flex; /* NEW: IE11, Chrome 29+, Opera 12.1+, Firefox 22+ */
    background-color: $flat-blue;
    text-align: right;
    @include large{
      display: none;
    }
    .container{
      .block{
          p,a,i{
            color: $white;
            margin: .25rem 0;
          }
          p,a{
            font-size: 1.25rem;
            padding:0 1rem 0 .3rem;
            font-weight: bold;
            text-decoration: none;
          }
          div{
            float: right;
          }
          i{
            padding-left: 2rem;
            font-size: 1.75rem;

          }
      }
    }
  }
  .main-header{
    position: relative;
    z-index: 2;
    background-color: $white;
    height: 50px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.26);
    @media screen and (min-width: 450px ){
      height: 75px;
    }
    @media screen and (min-width: 1300px ){
      height: 85px;
    }
    .logo-wrapper{
      background-color: $brand-main;
      height: 100%;
      padding: 0 16px;
    }
    .header-cta {
      background-color: $flat-blue;
      height: 75px;
      text-align: center;
      display: none;
      @media screen and (min-width: 1300px) {
        height: 85px;
      }
      @include large {
        display: -webkit-box; /* OLD: Safari,  iOS, Android browser, older WebKit browsers.  */
        display: -moz-box; /* OLD: Firefox (buggy) */
        display: -ms-flexbox; /* MID: IE 10 */
        display: -webkit-flex; /* NEW, Chrome 21–28, Safari 6.1+ */
        display: flex; /* NEW: IE11, Chrome 29+, Opera 12.1+, Firefox 22+ */
      }
      div{
        width: 100%;
        p.small-caps {
          margin: 0;
          color: $white;
          text-transform: lowercase;
          font-size: 1rem;
          @media screen and (min-width: 1300px) {
            font-size: 1rem;
          }
        }
        p,
        a {
          margin: 0;
          color: $white;
          font-weight: 700;
          text-decoration: none;
          text-transform: uppercase;
          white-space: nowrap;
          padding: 0 2rem;
          text-align: center;
        }
      }

    }
    .mobile-nav-toggle-wrapper{
      display: flex;
      @extend .flex-vertical-center;
      @include large{
        display: none;

      }

      .mobile-nav-toggle{
        margin-right: 1rem;
        @media screen and (min-width: 440px ){
          margin-right: 1.5rem;
        }

        .icon{
          font-size: 3rem;
          color: $brand-main;
        }

      }
      .mobile-header-cta{
        background-color: $flat-blue;
        height: 90px;
        .icon{
          font-size: 3rem;
          color: $white;
          padding: 0 1.5rem;
        }
      }

    }
.nav-wrapper{
  display: none;
  @include large{
    display: -webkit-box; /* OLD: Safari,  iOS, Android browser, older WebKit browsers.  */
    display: -moz-box; /* OLD: Firefox (buggy) */
    display: -ms-flexbox; /* MID: IE 10 */
    display: -webkit-flex; /* NEW, Chrome 21–28, Safari 6.1+ */
    display: flex; /* NEW: IE11, Chrome 29+, Opera 12.1+, Firefox 22+ */
  }
  nav{
    background-color: $white;
    height: 100%;

    .pure-menu{
      ul.pure-menu-list{
        @extend .flex-pull-right;
        position: relative;
        margin: 0;
        li{
          display: inline-block;
          zoom: 1;
          vertical-align: middle;
          margin: 0;
          padding: 0;
          position: relative;
          ul{
            display: none;
            position: absolute;
            top:100%;
            left: 0;
            margin: 0;
            padding: 0;
            z-index: -1;
            li{
              display: block;
            }
          }
        }
        li a{
          padding: 0 .65em;
          font-size: .9rem;
          line-height: 75px;
          text-decoration: none;
          @media screen and (min-width: 1180px ) {
            line-height: 75px;
            padding: 0 .75em;
          }
          @media screen and (min-width: 1300px ) {
            line-height: 85px;
            padding: 0 1em;
          }
        }
        li span{
          padding: 0 .65em;
          font-size: .9rem;
          line-height: 75px;
          cursor: pointer;
          @media screen and (min-width: 1180px ) {
            line-height: 75px;
            padding: 0 .75em;
          }
          @media screen and (min-width: 1300px ) {
            line-height: 85px;
            padding: 0 1em;
          }
        }
        li:hover, li a:hover, li span:hover{
          background-color: $ex-lt-grey;
        }
        li:hover{
          display: inline-block;
          zoom: 1;
          vertical-align: middle;
          margin: 0;
          padding: 0;

          ul{
            display: block;
            background-color: $white;
            box-shadow: 0 2px 5px rgba(0,0,0,0.26);
            li{
              display: block;
              padding: .5rem 0;
            }
            li a{
              line-height: 1.4rem;
              display: block;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

  }
  .mobile-menu{
    width: 100%;
    display: none;
    position: relative;
    z-index: 1;
    .pure-menu{
      width: 100%;
      background-color: $md-grey;
      ul.pure-menu-list{
        background-color: $md-grey;
        box-shadow: 0 2px 5px rgba(0,0,0,0.26);
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 0;
        li{
          list-style: none;
          margin: 0;
          padding: 0;
          font-size: 1.1rem;
          a{
            display: block;
            padding: .5rem 0;
            text-decoration: none;
          }
          span.nolink{
            display: block;
            padding: .5rem 0;
          }
          ul{
            box-shadow:none;
            background-color: $lt-grey;
            width: 100%;
            margin: 0;
            padding: 0;
            li{
              a{
                display: block;
                padding: .5rem 0;
                text-decoration: none;

              }
            }
          }
        }

      }
    }

  }
}
.sticky {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
}
//End Header Section
