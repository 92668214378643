.services-section {
  .container {
    padding: 2rem 0 1rem;
    .services-section_content-wrapper {
      display: block;
      padding: 0 1rem;
      margin: 0 auto;
      .services-container {
        .services-outer-wrapper {
          margin-bottom: 1rem;
          padding: 0 1rem;
          h2 {
            font-size: 1.5rem;
            text-transform: none;
            letter-spacing: normal;
          }
          a {
            text-decoration: none;
          }
          h2, p {
            padding: 0;
          }
          a.button-primary {
            display: inline-block;
            cursor: pointer;
            background: white;
            border: 2px solid #009491;
            text-align: center;
            padding: .7em;
            font-size: 1rem;
            box-shadow: none;
            vertical-align: middle;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            color: #009491;
            font-weight: 400;
            transition: background-color .25s ease-in-out, color .25s ease-in-out;

            span {
              color: $brand-main;
              text-transform: uppercase;
              text-decoration: none;
            }

            &:hover {
              background-color: $brand-main;

              span {
                color: $white;
              }
            }
          }
        }
      }
      .slick-arrow {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 0;
        display: block;
        width: 3rem;
        height: 3rem;
        padding: 0;
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        background: $brand-main;
        transition: background-color .25s ease-in-out;
        z-index: 1;
        &:hover,
        &:active {
          background: $flat-blue;

        }
      }

      .slick-prev {
        right: 4rem
      }

      .slick-next {
        right: 1rem
      }

      .slick-prev:before,
      .slick-next:before {
        display: inline-block;
        font-family: "Ionicons";
        font-size: 2rem;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        text-rendering: auto;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: $white;
      }
      .slick-prev:before {
        content: "\f124";
      }
      .slick-next:before {
        content: "\f125";
      }

    }
  }
}
