.content-section-widget {
  position: relative;
  margin-top: 2rem;
  &.follows-hero {
    margin-top: -3rem;
    @include medium {
      margin-top: -5rem;
    }
    @include large {
      margin-top: -8rem;
    }
  }
  .content-section-widget-wrapper {
    padding: 0 0 2rem;
    background-color: $white;
    box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
    float: left;
    margin-bottom: 3rem;
    h1 {
      margin-bottom: 0;
    }
    p:nth-of-type(1) {
      margin-top: 1rem;
    }
  }
}
