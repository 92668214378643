.interior-content-section {
   position: relative;
   .interior-content-section_content-wrapper {
     padding: 0 0 2rem;
     background-color: $white;
     box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
     float: left;
     margin-bottom: 3rem;
     //h1{
     //  padding: 0 2rem;
     //}
   }
   .interior-sidebar-section_content-wrapper {
     position: relative;
     float: right;
     padding: 1rem 0 3rem 0;
     width: 100%;
     @include large {
       width: 30%;
     }
     .interior-sidebar_content {
       background-color: $white;
       box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
       .container{

       }
       header {
         background-color: $brand-main;
         margin-bottom: .5rem;
         h2 {
           color: $white;
           padding: 1rem 2rem;
           margin: 0;
         }
       }
       form.form-custom {
         display: block;
         padding: 0 2rem 1rem;
         @include large {
           padding: 0 2rem 1rem;
         }
         .input-wrapper {
           width: 100%;
         }
         .center-button-wrapper {
           margin-top: .5rem;
           button {
             margin: 0 !important;
             font-size: 1.25rem;
             text-transform: uppercase;
           }
         }

       }
       .webform-client-form{
         display: block;
         padding: 0 2rem 1rem;
         @include large {
           padding: 0 2rem 1rem;
         }
         .input-wrapper {
           width: 100%;
         }
         button {
           display: block;
           margin:  .5rem auto 0 !important;
           font-size: 1.25rem;
           text-transform: uppercase;
         }
         .form-actions {
           margin-top: .5rem;
           input[type="submit"]{
             display: block;
             margin:  .8rem auto 0 !important;
             font-size: 1.25rem;
             text-transform: uppercase;
           }
         }
       }

     }

   }
 }
.interior-full-width-content-section {
  position: relative;
  margin-top: 1rem;
  .interior-full-width-content-section_content-wrapper {
    padding: 0 0 2rem;
    background-color: $white;
    box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
    float: left;
    margin-bottom: 3rem;
    .interior-full-width-contact-us-form{
      padding: 0 2rem;
    }
  }
}
