$ex-lt-grey: #eaeaea;
$lt-grey: #d9d9d9;
$md-grey: #cccccc;
$dk-grey: #313131;

$brand-main: #009491;
$brand-main-dk: #00807d;

$flat-blue: #1B4353;
$chocolate: #262123;
$off-white: #ededed;

$white: #ffffff;
$black: #000000;
$shadow: #090404;

//Action Colors
$red: #d0021b;
$green: #88b651;
$placeholder-blue: #7ecbe6;


$font-stack: 'Lato';
$break-xsmall: 25em; /* min-width 400px */
$break-small: 35.5em; /* min-width 568 */
$break-medium: 48em; /* min-width 768 */
$break-large: 64em; /* min-width 1024 */
$break-xlarge: 80em; /* min-width 1280 */

@mixin xlarge {
  @media (min-width: #{$break-xlarge}) {
    @content;
  }
}
@mixin large {
  @media (min-width: #{$break-large}) {
    @content;
  }
}

@mixin medium {
  @media (min-width: #{$break-medium}) {
    @content;
  }
}

@mixin small {
  @media (min-width: #{$break-small}) {
    @content;
  }
}
@mixin xsmall {
  @media (min-width: #{$break-xsmall}) {
    @content;
  }
}

@mixin retina {
  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}



