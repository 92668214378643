.practice-area-hero-section{
  position: relative;
  z-index: 0;
  .main-hero{
    position: relative;
    .caption-wrapper{
      position: absolute;
      height: 100%;
      .caption{
        box-shadow: 0 2px 5px rgba(0,0,0,0.26);
        padding: .5rem 0;
        margin: auto auto -2rem;
        background-color: $flat-blue;
        display: none;
        @include small{
          display: flex;
          margin: auto 0 auto auto;
        }
        h1{
          font-size: 2rem;
          line-height: 1;
          color: $white;
          @include medium{
            font-size: 2.5rem;
          }
          @include large{
            font-size: 3rem;
          }
          .no-break {
            white-space: nowrap;
            sup {
              font-size: 1rem;
              vertical-align: super;
              font-weight: 200;
            }
          }
        }
        p.subhead{
          color: $white;
          font-size: 1.25rem;
          @include large{
            font-size: 1rem;
          }
        }

      }
    }
  }
}
.practice-area-content-section {
  position: relative;
  margin-top: 2rem;
  &.follows-hero {
    margin-top: -3rem;
    @include medium {
      margin-top: -5rem;
    }
    @include large {
      margin-top: -8rem;
    }
  }
  .practice-area-content-section_content-wrapper {
    padding: 0 0 2rem;
    background-color: $white;
    box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
    float: left;
    margin-bottom: 3rem;

  }
  .practice-area-sidebar-section_content-wrapper {
    position: relative;
    float: right;
    padding: 0 0 3rem 0;
    width: 100%;
    @include large {
      width: 30%;
    }
    .practice-area-sidebar_content {
      background-color: $white;
      box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
      header {
        background-color: $brand-main;
        margin-bottom: .5rem;
        h2 {
          color: $white;
          padding: 1rem 2rem;
          margin: 0;
        }
      }
      form.form-custom {
        display: block;
        padding: 0 2rem 1rem;
        @include large {
          padding: 0 2rem 1rem;
        }
        .input-wrapper {
          width: 100%;
        }
        .center-button-wrapper {
          margin-top: .5rem;
          button {
            margin: 0 !important;
            font-size: 1.25rem;
            text-transform: uppercase;
          }
        }

      }
      .webform-client-form{
        display: block;
        padding: 0 2rem 1rem;
        @include large {
          padding: 0 2rem 1rem;
        }
        .input-wrapper {
          width: 100%;
        }
        button {
          display: block;
          margin:  .5rem auto 0 !important;
          font-size: 1.25rem;
          text-transform: uppercase;
        }
        .form-actions {
          margin-top: .5rem;
          input[type="submit"]{
            display: block;
            margin:  .8rem auto 0 !important;
            font-size: 1.25rem;
            text-transform: uppercase;
          }
        }
      }
    }

  }
}
.practice-area-contact-us-section {
  display: block;
  @include large {
    display: none;

  }
  .contact-us-section_container {
    .contact-us-section_header {
      background-color: $brand-main;
      h1 {
        color: $white;
      }
    }
    form.form-custom {
      display: block;
      background-color: $white;
      padding: 2rem;
      margin-bottom: 3rem;
      .form-row:nth-child(3) {
        padding-bottom: 0;
      }
      .center-button-wrapper {
        margin-top: .5rem;
        button {
          margin: 0 !important;
          font-size: 1.25rem;
          text-transform: uppercase;
        }
      }
    }
  }

}
