/*!
Pure v0.6.0
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
https://github.com/yahoo/pure/blob/master/LICENSE.md
*/
/*csslint box-model:false*/
/*
Box-model set to false because we're setting a height on select elements, which
also have border and padding. This is done because some browsers don't render
the padding. We explicitly set the box-model for select elements to border-box,
so we can ignore the csslint warning.
*/

.pure-form {
  input {
    &[type="text"], &[type="password"], &[type="email"], &[type="url"], &[type="date"], &[type="month"], &[type="time"], &[type="datetime"], &[type="datetime-local"], &[type="week"], &[type="number"], &[type="search"], &[type="tel"], &[type="color"] {
      padding: 0.5em 0.6em;
      display: inline-block;
      border: 1px solid #ccc;
      box-shadow: inset 0 1px 3px #ddd;
      border-radius: 4px;
      vertical-align: middle;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }

  select, textarea {
    padding: 0.5em 0.6em;
    display: inline-block;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  input {
    &:not([type]) {
      padding: 0.5em 0.6em;
      display: inline-block;
      border: 1px solid #ccc;
      box-shadow: inset 0 1px 3px #ddd;
      border-radius: 4px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    &[type="color"] {
      padding: 0.2em 0.5em;
    }

    &[type="text"]:focus, &[type="password"]:focus, &[type="email"]:focus, &[type="url"]:focus, &[type="date"]:focus, &[type="month"]:focus, &[type="time"]:focus, &[type="datetime"]:focus, &[type="datetime-local"]:focus, &[type="week"]:focus, &[type="number"]:focus, &[type="search"]:focus, &[type="tel"]:focus, &[type="color"]:focus {
      outline: 0;
      border-color: #129FEA;
    }
  }

  select:focus, textarea:focus {
    outline: 0;
    border-color: #129FEA;
  }

  input {
    &:not([type]):focus {
      outline: 0;
      border-color: #129FEA;
    }

    &[type="file"]:focus, &[type="radio"]:focus, &[type="checkbox"]:focus {
      outline: thin solid #129FEA;
      outline: 1px auto #129FEA;
    }
  }

  .pure-checkbox, .pure-radio {
    margin: 0.5em 0;
    display: block;
  }

  input {
    &[type="text"][disabled], &[type="password"][disabled], &[type="email"][disabled], &[type="url"][disabled], &[type="date"][disabled], &[type="month"][disabled], &[type="time"][disabled], &[type="datetime"][disabled], &[type="datetime-local"][disabled], &[type="week"][disabled], &[type="number"][disabled], &[type="search"][disabled], &[type="tel"][disabled], &[type="color"][disabled] {
      cursor: not-allowed;
      background-color: #eaeded;
      color: #cad2d3;
    }
  }

  select[disabled], textarea[disabled] {
    cursor: not-allowed;
    background-color: #eaeded;
    color: #cad2d3;
  }

  input {
    &:not([type])[disabled] {
      cursor: not-allowed;
      background-color: #eaeded;
      color: #cad2d3;
    }

    &[readonly] {
      background-color: #eee;

      /* menu hover bg color */
      color: #777;

      /* menu text color */
      border-color: #ccc;
    }
  }

  select[readonly], textarea[readonly] {
    background-color: #eee;

    /* menu hover bg color */
    color: #777;

    /* menu text color */
    border-color: #ccc;
  }

  input:focus:invalid, textarea:focus:invalid, select:focus:invalid {
    color: #b94a48;
    border-color: #e9322d;
  }

  input {
    &[type="file"]:focus:invalid:focus, &[type="radio"]:focus:invalid:focus, &[type="checkbox"]:focus:invalid:focus {
      outline-color: #e9322d;
    }
  }

  select {
    /* Normalizes the height; padding is not sufficient. */
    height: 2.25em;
    border: 1px solid #ccc;
    background-color: white;

    &[multiple] {
      height: auto;
    }
  }

  label {
    margin: 0.5em 0 0.2em;
  }

  fieldset {
    margin: 0;
    padding: 0.35em 0 0.75em;
    border: 0;
  }

  legend {
    display: block;
    width: 100%;
    padding: 0.3em 0;
    margin-bottom: 0.3em;
    color: #333;
    border-bottom: 1px solid #e5e5e5;
  }
}

/*
Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
since IE8 won't execute CSS that contains a CSS3 selector.
*/

/* Chrome (as of v.32/34 on OS X) needs additional room for color to display. */
/* May be able to remove this tweak as color inputs become more standardized across browsers. */

/*
Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
since IE8 won't execute CSS that contains a CSS3 selector.
*/

/*
Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
since IE8 won't execute CSS that contains a CSS3 selector.
*/

.pure-form-stacked {
  input {
    &[type="text"], &[type="password"], &[type="email"], &[type="url"], &[type="date"], &[type="month"], &[type="time"], &[type="datetime"], &[type="datetime-local"], &[type="week"], &[type="number"], &[type="search"], &[type="tel"], &[type="color"], &[type="file"] {
      display: block;
      margin: 0.25em 0;
    }
  }

  select, label, textarea, input:not([type]) {
    display: block;
    margin: 0.25em 0;
  }
}

/*
Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
since IE8 won't execute CSS that contains a CSS3 selector.
*/

.pure-form-aligned {
  input, textarea, select, .pure-help-inline {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: middle;
  }
}

.pure-form-message-inline {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  vertical-align: middle;
}

.pure-form-aligned {
  textarea {
    vertical-align: top;
  }

  .pure-control-group {
    margin-bottom: 0.5em;

    label {
      text-align: right;
      display: inline-block;
      vertical-align: middle;
      width: 10em;
      margin: 0 1em 0 0;
    }
  }

  .pure-controls {
    margin: 1.5em 0 0 11em;
  }
}

/* Aligned Forms */

/* Rounded Inputs */

.pure-form {
  input.pure-input-rounded, .pure-input-rounded {
    border-radius: 2em;
    padding: 0.5em 1em;
  }

  .pure-group {
    fieldset {
      margin-bottom: 10px;
    }

    input, textarea {
      display: block;
      padding: 10px;
      margin: 0 0 -1px;
      border-radius: 0;
      position: relative;
      top: -1px;
    }

    input:focus, textarea:focus {
      z-index: 3;
    }

    input:first-child, textarea:first-child {
      top: 1px;
      border-radius: 4px 4px 0 0;
      margin: 0;
    }

    input:first-child:last-child, textarea:first-child:last-child {
      top: 1px;
      border-radius: 4px;
      margin: 0;
    }

    input:last-child, textarea:last-child {
      top: -2px;
      border-radius: 0 0 4px 4px;
      margin: 0;
    }

    button {
      margin: 0.35em 0;
    }
  }

  .pure-input-1 {
    width: 100%;
  }

  .pure-input-2-3 {
    width: 66%;
  }

  .pure-input-1-2 {
    width: 50%;
  }

  .pure-input-1-3 {
    width: 33%;
  }

  .pure-input-1-4 {
    width: 25%;
  }

  .pure-help-inline {
    display: inline-block;
    padding-left: 0.3em;
    color: #666;
    vertical-align: middle;
    font-size: 0.875em;
  }
}

/* Grouped Inputs */

/* Inline help for forms */
/* NOTE: pure-help-inline is deprecated. Use .pure-form-message-inline instead. */

.pure-form-message-inline {
  display: inline-block;
  padding-left: 0.3em;
  color: #666;
  vertical-align: middle;
  font-size: 0.875em;
}

/* Block help for forms */

.pure-form-message {
  display: block;
  color: #666;
  font-size: 0.875em;
}

@media only screen and (max-width: 480px) {
  .pure-form {
    button[type="submit"] {
      margin: 0.7em 0 0;
    }

    input {
      &:not([type]), &[type="text"], &[type="password"], &[type="email"], &[type="url"], &[type="date"], &[type="month"], &[type="time"], &[type="datetime"], &[type="datetime-local"], &[type="week"], &[type="number"], &[type="search"], &[type="tel"], &[type="color"] {
        margin-bottom: 0.3em;
        display: block;
      }
    }

    label {
      margin-bottom: 0.3em;
      display: block;
    }
  }

  .pure-group input {
    &:not([type]), &[type="text"], &[type="password"], &[type="email"], &[type="url"], &[type="date"], &[type="month"], &[type="time"], &[type="datetime"], &[type="datetime-local"], &[type="week"], &[type="number"], &[type="search"], &[type="tel"], &[type="color"] {
      margin-bottom: 0;
    }
  }

  .pure-form-aligned {
    .pure-control-group label {
      margin-bottom: 0.3em;
      text-align: left;
      display: block;
      width: 100%;
    }

    .pure-controls {
      margin: 1.5em 0 0 0;
    }
  }

  /* NOTE: pure-help-inline is deprecated. Use .pure-form-message-inline instead. */

  .pure-form .pure-help-inline, .pure-form-message-inline, .pure-form-message {
    display: block;
    font-size: 0.75em;

    /* Increased bottom padding to make it group with its related input element. */
    padding: 0.2em 0 0.8em;
  }
}
