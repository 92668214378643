html {
  box-sizing: border-box;
  font-size: 14px;
  background: $lt-grey;
  letter-spacing: normal !important;
  @include small {
    font-size: 14px;
  }
  @include medium {
    font-size: 16px;
  }
  @include large {
    font-size: 17px;
  }
  @include xlarge {
    font-size: 18px;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

picture {
  width: 100%;
  display: block;
  img{
    width: 100%;
  }
}
.pure-g{
  letter-spacing: normal !important;
}
.pure-g [class *= "pure-u"] {
  font-family: $font-stack;

  p {
    color: $dk-grey;
    font-style: normal;
    font-weight: 300;
    line-height: 1.4;
    font-size: 1rem;
    margin: 0 0 1rem;
    padding: 0 2rem;
  }
  p:nth-last-of-type(1) {
    margin: 0;
  }
  p:nth-of-type(1) {
    margin: 0 0 1rem;
  }
  ul, ol{
    padding:0 2rem 0 4rem;
  }
  ul{
    li{
      ul{
        padding:0 2rem;
      }
    }
  }
  li {
    color: $dk-grey;
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
  }
  dl{
    padding:0 2rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $dk-grey;
    margin: 1rem 0;
    font-style: normal;
    font-weight: 900;
    line-height: 1.2;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 0 2rem;

  }
  h1 {
    font-size: 2rem;
    @include small {
      font-size: 2.5rem;
    }
    @include medium {
      font-size: 3rem;
    }
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
    color: $dk-grey;
    margin: .75rem 0;
    font-weight: 700;
    letter-spacing: 0;
  }

  a {
    cursor: pointer;
    font-family: $font-stack;
    color: $dk-grey;
  }
  picture {
    width: 100%;
    display: block;
    img{
      width: 100%;
    }
  }
}

//global layout settings
section {
  padding: 0 3.5%;
  &.hero {
    padding: 0;
  }
  .container {
    background-color: $white;
    padding: 2rem 0;
    display: block;
    margin: 0 auto 3rem;
    box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
    .block {
      header {
        //padding-bottom: 1rem;
        h2 {
          margin-top: 0;
          text-transform: uppercase;
          padding: 0 2rem;
        }
        p {
          padding: 0 2rem;
        }
      }
    }
  }
}
.breadcrumb{
  padding: 2rem 2rem 0;
  font-size: .65rem;
  text-transform: uppercase;
  a{
    text-decoration: none;
  }
}

h1#page-title {
  margin-top: .5rem;
}

#user-login{
  width: 100%;
  padding: 0 3.5%;
    .container {
      background-color: $white;
      padding: 1rem 2rem;
      display: block;
      margin: 1rem auto 3rem;
      box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
  }
  input {
    &[type="text"], &[type="password"], &[type="email"], &[type="url"], &[type="date"], &[type="month"], &[type="time"], &[type="datetime"], &[type="datetime-local"], &[type="week"], &[type="number"], &[type="search"], &[type="tel"], &[type="color"] {
      background: white;
      border: 1px solid $dk-grey;
      position: relative;
      border-radius: 0px;
      padding: .7em .75em;
      font-size: 1rem;
      box-shadow: none;
      vertical-align: middle;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
  label {
    display: block;
    font-weight: 300;
    margin-bottom: .5rem;
  }
  button, html input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
    background: white;
    border: 2px solid $brand-main;
    position: relative;
    border-radius: 0px;
    padding: .7em 1.5em;
    font-size: 1rem;
    margin-top: .4em;
    margin-bottom: .4em;
    box-shadow: none;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: $brand-main;
    font-weight: 400;
    text-transform: uppercase !important;
    transition: background-color .25s ease-in-out, color .25s ease-in-out;
  }

  button:hover, html input[type="button"]:hover, input[type="reset"]:hover, input[type="submit"]:hover {
    background: none;
    background-color: $brand-main;
    color: $white;
  }

  button:focus, html input[type="button"]:focus, input[type="reset"]:focus, input[type="submit"]:focus {
    background: none;
    background-color: $white;
    color: $brand-main;
    outline: none;
  }

  input:focus, select:focus, textarea:focus {
    border: 1px solid $brand-main !important;
    outline: none;
  }
  .description{
    font-weight: 300;
    margin-top: .25rem;
  }
}

.profile{
  background-color: $white;
  padding: 1rem 2rem 2rem;
  display: block;
  margin: 1rem 2rem;
  box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
  width: 100%;
}

//layout helpers

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.pure-g--gutter {
  padding: 0 3.5%;
}

.pure-g--content-gutter {
  padding: 0 2rem;
  @include medium {
    padding: 0 1em;
  }
}

.small-caps {
  font-variant: small-caps;
  letter-spacing: 2px;
  text-transform: lowercase;
}

.no-margin {
  margin: 0;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.flex {
  display: -webkit-box; /* OLD: Safari,  iOS, Android browser, older WebKit browsers.  */
  display: -moz-box; /* OLD: Firefox (buggy) */
  display: -ms-flexbox; /* MID: IE 10 */
  display: -webkit-flex; /* NEW, Chrome 21–28, Safari 6.1+ */
  display: flex; /* NEW: IE11, Chrome 29+, Opera 12.1+, Firefox 22+ */
}

.flex-vertical-center {
  display: -webkit-box; /* OLD: Safari,  iOS, Android browser, older WebKit browsers.  */
  display: -moz-box; /* OLD: Firefox (buggy) */
  display: -ms-flexbox; /* MID: IE 10 */
  display: -webkit-flex; /* NEW, Chrome 21–28, Safari 6.1+ */
  display: flex; /* NEW: IE11, Chrome 29+, Opera 12.1+, Firefox 22+ */

  -webkit-box-align: center;
  -moz-box-align: center; /* OLD… */
  -ms-flex-align: center; /* You know the drill now… */
  -webkit-align-items: center;
  align-items: center;
}

.flex-horizontal-center {
  display: -webkit-box; /* OLD: Safari,  iOS, Android browser, older WebKit browsers.  */
  display: -moz-box; /* OLD: Firefox (buggy) */
  display: -ms-flexbox; /* MID: IE 10 */
  display: -webkit-flex; /* NEW, Chrome 21–28, Safari 6.1+ */
  display: flex; /* NEW: IE11, Chrome 29+, Opera 12.1+, Firefox 22+ */
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.flex-total-center {
  display: -webkit-box; /* OLD: Safari,  iOS, Android browser, older WebKit browsers.  */
  display: -moz-box; /* OLD: Firefox (buggy) */
  display: -ms-flexbox; /* MID: IE 10 */
  display: -webkit-flex; /* NEW, Chrome 21–28, Safari 6.1+ */
  display: flex; /* NEW: IE11, Chrome 29+, Opera 12.1+, Firefox 22+ */

  -webkit-box-align: center;
  -moz-box-align: center; /* OLD… */
  -ms-flex-align: center; /* You know the drill now… */
  -webkit-align-items: center;
  align-items: center;

  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.flex-column {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.flex-row {
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.flex-pull-left {
  margin-right: auto !important;
}

.flex-pull-right {
  margin-left: auto !important;
}

.flex-pull-down {
  margin-top: auto !important;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

::-ms-clear {
  display: none;
}

.mobile {
  display: none;
  @include medium {
    display: block;
  }
}

.desktop {
  display: block;
  @include medium {
    display: none;
  }
}

//pure overrides

.pure-menu-link {
  color: $black;
  padding: 0;
}

.pure-menu-active > .pure-menu-link,
.pure-menu-link:hover,
.pure-menu-link:focus {
  color: $black;
  background-color: #EFEFEF;
}

.pure-form legend {
  color: $dk-grey;
  font-size: 1.25rem;
  margin: 0 0 1rem;
  font-family: $font-stack;
  font-style: normal;
  font-weight: 300;
  border-bottom: none;
}

.pure-form-stacked input {

}

.pure-button-primary, .pure-button-selected, a.pure-button-primary, a.pure-button-selected {
  background-color: $dk-grey;
  color: #fff;
  margin-top: 1rem;
}

/* Form Elements */
input, select, textarea, button {
  font-family: $font-stack !important;
}

.form-custom input, .form-custom select, .form-custom textarea, .form-custom button {
  margin-top: .4em !important;
  margin-bottom: .4em !important;
  font-weight: 300;
}

.form-custom input {
  &[type="text"], &[type="password"], &[type="email"], &[type="url"], &[type="date"], &[type="month"], &[type="time"], &[type="datetime"], &[type="datetime-local"], &[type="week"], &[type="number"], &[type="search"], &[type="tel"], &[type="color"] {
    background: white;
    border: 1px solid $dk-grey;
    position: relative;
    border-radius: 0px;
    padding: .7em .75em;
    font-size: 1rem;
    box-shadow: none;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.form-custom input {
  &[type="text"][disabled], &[type="password"][disabled], &[type="email"][disabled], &[type="url"][disabled], &[type="date"][disabled], &[type="month"][disabled], &[type="time"][disabled], &[type="datetime"][disabled], &[type="datetime-local"][disabled], &[type="week"][disabled], &[type="number"][disabled], &[type="search"][disabled], &[type="tel"][disabled], &[type="color"][disabled] {
    background-color: $white;
  }
  &[type="submit"][disabled] {
    cursor: not-allowed;
  }
}

.form-custom select[disabled], .form-custom textarea[disabled] {
  background-color: $white;
  color: $dk-grey;
}

.form-custom select, .form-custom textarea {
  background: transparent;
  border: 1px solid $dk-grey;
  position: relative;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: .5em .75em;
  font-size: 1rem;
  box-shadow: none;
  vertical-align: middle;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: $dk-grey;
}

.form-custom label {
  margin: 1em 0;
  text-transform: uppercase;
  font-size: .7rem;
  color: $dk-grey;
}

.form-custom input::-webkit-input-placeholder {
  color: $dk-grey;
  font-family: $font-stack;
}

.form-custom input:-moz-placeholder { /* Firefox 18- */
  color: $dk-grey;
  font-family: $font-stack;
}

.form-custom input::-moz-placeholder { /* Firefox 19+ */
  color: $dk-grey;
  font-family: $font-stack;
}

.form-custom input:-ms-input-placeholder {
  color: $dk-grey;
  font-family: $font-stack;
}

.form-custom textarea::-webkit-input-placeholder {
  color: $dk-grey;
  font-family: $font-stack;
}

.form-custom textarea:-moz-placeholder { /* Firefox 18- */
  color: $dk-grey;
  font-family: $font-stack;
}

.form-custom textarea::-moz-placeholder { /* Firefox 19+ */
  color: $dk-grey;
  font-family: $font-stack;
}

.form-custom textarea:-ms-input-placeholder {
  color: $dk-grey;
  font-family: $font-stack;
}

.form-custom button, .form-custom html input[type="button"], .form-custom input[type="reset"], .form-custom input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  background: white;
  border: 2px solid $brand-main;
  position: relative;
  border-radius: 0px;
  padding: .7em 1.5em;
  font-size: 1rem;
  margin-top: .4em;
  margin-bottom: .4em;
  box-shadow: none;
  vertical-align: middle;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: $brand-main;
  font-weight: 400;
  transition: background-color .25s ease-in-out, color .25s ease-in-out;
}

.form-custom button:hover, .form-custom html input[type="button"]:hover, .form-custom input[type="reset"]:hover, .form-custom input[type="submit"]:hover {
  background: none;
  background-color: $brand-main;
  color: $white;
}

.form-custom button:focus, .form-custom html input[type="button"]:focus, .form-custom input[type="reset"]:focus, .form-custom input[type="submit"]:focus {
  background: none;
  background-color: $white;
  color: $brand-main;
  outline: none;
}

.form-custom input:focus, .form-custom select:focus, .form-custom textarea:focus {
  border: 1px solid $brand-main !important;
  outline: none;
}

.form-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include medium {
    flex-direction: row;
  }
}

.center-button-wrapper {
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.form-custom .input-wrapper {
  position: relative;
  margin: 0;
  width: 100%;
  @include medium{
    width: 48.5%;

  }
}

.form-custom .textarea-wrapper {
  position: relative;
  padding: .25rem 0 0;
  width: 100%;
  clear: both;
}

.form-custom .select-wrapper {
  position: relative;
  margin: -1.1em 0 3em;
  padding-bottom: 3px;
  height: 100%;
}

.webform-client-form input[type="submit"], .form-custom input[type="submit"] {
  margin: 1rem auto 0 !important;
  display: block;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 400;
}

.grippie {
  display: none !important;
}

.form-item, .form-actions {
  margin: 0;
}

//.form-custom button, html .form-custom input[type="button"], .form-custom input[type="reset"], .form-custom input[type="submit"] {
//  -webkit-appearance: button;
//  cursor: pointer;
//  background: white;
//  border: 2px solid $brand-main;
//  position: relative;
//  border-radius: 0px;
//  padding: .7em 1.5em;
//  font-size: 1rem;
//  margin-top: .25em;
//  margin-bottom: .25em;
//  box-shadow: none;
//  vertical-align: middle;
//  -webkit-box-sizing: border-box;
//  -moz-box-sizing: border-box;
//  box-sizing: border-box;
//  color: $brand-main;
//  font-weight: 400;
//  text-transform: uppercase;
//  transition: background-color .25s ease-in-out, color .25s ease-in-out;
//}
//
//.form-custom button:hover, html .form-custom input[type="button"]:hover, .form-custom input[type="reset"]:hover, .form-custom input[type="submit"]:hover {
//  background: none;
//  background-color: $brand-main;
//  color: $white;
//}
//
//.form-custom button:focus, html .form-custom input[type="button"]:focus, .form-custom input[type="reset"]:focus, .form-custominput[type="submit"]:focus {
//  background: none;
//  background-color: $white;
//  color: $brand-main;
//  outline: none;
//}

ul.pager {
  margin: .5rem 2rem 0;
  padding: 0 !important;
}

.item-list .pager li {
  margin: 0;
  background: $lt-grey;
  padding: 0.25rem;
  color: $dk-grey;
  a{
    color: $dk-grey;
  }
}
.item-list li.pager-current{
  background: $flat-blue;
  color: $white;
  a{
    color: $white;
  }
}

.live-chat-tab {
  background-color: $flat-blue;
  -webkit-border-radius: 0;
  -moz-border-radius-topleft: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  text-indent: 0;
  display: inline-block;
  color: $white;
  font-style: normal;
  display: block;
  padding: 0;
  position: fixed;
  left: 0px;
  bottom: 5vh;
  z-index: 5000;
  text-decoration: none;
  text-align: center;
  -ms-text-rendering: optimizeLegibility;
  text-rendering: optimizeLegibility;
  transform: rotate(-90deg);
  transform-origin: left top 0;
  box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
  @include small {
    bottom: 30vh;

  }
  h2 {
    white-space: nowrap;
    color: $white;
    font-size: 1rem;
  }
}

.form-pop-up {
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  @extend .flex-total-center;
  z-index: 5001;
  background-color: rgba(0, 0, 0, .7);
  .form-wrapper {
    background-color: $white;
    padding: 0 0 1rem;
    .form-header {
      background-color: $brand-main;
      margin-bottom: .5rem;
      h1 {
        color: $white;
      }
    }
    form.form-custom {
      display: block;
      padding: 0 2rem;
      background-color: $white;
      //@include large{
      //    padding: 0 2rem;
      //}

      .input-wrapper {
        width: 100%;
      }
      .center-button-wrapper {
        margin-top: .5rem;
        button {
          margin: 0 !important;
          font-size: 1.25rem;
          text-transform: uppercase;
        }
      }

    }

  }

}

.pure-menu-allow-hover:hover > .pure-menu-children, .pure-menu-active > .pure-menu-children {
  display: block;
  position: absolute;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);

}

.pure-menu-horizontal .pure-menu-has-children > .pure-menu-link:after {
  display: none;
}

.pure-menu-children li {
  line-height: 1;
  padding: 0;
  margin: 0 !important;
}

ul.pure-menu-children li a {
  padding: 0;
  line-height: 44px !important;
}

/* Animated throbber */
html.js input.form-autocomplete {
  background: none;
}
html.js input.throbbing {
  background: none;
}
/* Throbber */
.ajax-progress {
  display: none;
}
.ajax-progress .throbber {
  background: none;
  float: left; /* LTR */
  height: 0;
  margin: 0;
  width: 0;
}
.ajax-progress .message {
  padding: 0;
}
tr .ajax-progress .throbber {
  margin: 0;
}
.ajax-progress-bar {
  width: 0;
}
