.interior-hero-section{
  position: relative;
  z-index: 0;
  margin-top: 10vh;
  .main-hero{
    position: relative;
    .caption-wrapper{
      position: absolute;
      height: 100%;
      .caption{
        box-shadow: 0 2px 5px rgba(0,0,0,0.26);
        padding: .5rem 0;
        margin: auto auto -2rem;
        background-color: $flat-blue;
        display: none;
        @include small{
          display: block;
          margin: -10vh 0 -2rem auto;
        }
        h1{
          font-size: 2rem;
          line-height: 1;
          color: $white;
          @include medium{
            font-size: 2.5rem;
          }
          @include large{
            font-size: 3rem;
          }
          .no-break {
            white-space: nowrap;
            sup {
              font-size: 1rem;
              vertical-align: super;
              font-weight: 200;
            }
          }
        }
        p.subhead{
          color: $white;
          font-size: 1.25rem;
          @include large{
            font-size: 1rem;
          }
        }

      }
    }
  }
}