//Hero Section
.hero-section {
  position: relative;
  z-index: 0;
  padding: 0;

  .main-hero {
    position: relative;

    .caption-wrapper {
      position: absolute;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      .caption {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
        padding: .5rem 0;
        margin-bottom: 3rem;
        background-color: $flat-blue;
        @include small {
          margin-bottom: 4.5rem
        }
        @include medium {
          margin-bottom: 8rem;
        }

        h1 {
          font-size: 2rem;
          line-height: 1;
          color: $white;
          margin: 1rem 0;
          @include medium {
            font-size: 2.5rem;
          }
          @include large {
            font-size: 3rem;
          }

          .no-break {
            white-space: nowrap;

            sup {
              font-size: 1rem;
              vertical-align: super;
              font-weight: 200;
            }
          }
        }

        p {
          color: $white;
          font-size: 1rem;
          @include small {
            font-size: 1rem;
          }
        }
      }
    }

    picture img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }

  .campaign-hero {
    display: flex;
    height: 1000px;
    width: 100%;
    position: relative;
    align-items: flex-end;
    overflow: hidden;

    @include large {
      height: 750px;
      width: 100%;
      position: relative;
      align-items: center;
      overflow: hidden;
    }

    .content-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 0 5rem;
      position: relative;
      background: linear-gradient(180deg, rgba(38,33,35,0) 10%, rgba(38,33,35,1) 30%);
      &:after {
        content: '';
        background-color: #262123;
        position: absolute;
        width: 100%;
        height: 50%;
        bottom: -50%;
      }

      @include large {
        background: none;
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        margin: 0 3.5rem 0 auto;
        width: 60%;
        &:after {
          content: none;
        }
      }

      .campaign-heading-wrapper {
        display: flex;
        flex-direction: column;
        .campaign-heading-wrapper__line-one,
        .campaign-heading-wrapper__line-two {
          .campaign-heading {
            color: $white;
            font-size: calc(5.625rem - 2.5rem);
            font-family: "Crimson Pro", serif;
            line-height: 0.6;
            margin: 0;
            padding: 0;
            text-transform: none;
            text-shadow: 4px 4px 0 $chocolate;
            @include medium {
              font-size: calc(5.625rem - 2rem);
            }
            @include large {
              font-size: calc(5.625rem - 1.5rem);
            }
            @media (min-width: 1100px) {
              text-shadow: none;
              font-size: calc(5.625rem - 1rem);
            }
            @media (min-width: 1200px) {
              font-size: calc(5.625rem - .5rem);
            }
            @media (min-width: 1300px) {
              font-size: 5.625rem;
            }
            .campaign-heading-plus {
              font-size: calc(8.125rem - 3.5rem);

              @include small {
                font-size: calc(8.125rem - 2.5rem);
              }
              @include medium {
                font-size: calc(8.125rem - 2rem);
              }
              @include large {
                font-size: calc(8.125rem - 1.5rem);
              }
              @media (min-width: 1100px) {
                font-size: calc(8.125rem - 1rem);
              }
              @media (min-width: 1200px) {
                font-size: calc(8.125rem - .5rem);
              }
              @media (min-width: 1300px) {
                font-size: 8.125rem;
              }
            }
            .campaign-heading-plus-two {
              font-size: calc(10rem - 4.5rem);
              @include small {
                font-size: calc(10rem - 2.5rem);
              }
              @include medium {
                font-size: calc(10rem - 2rem);
              }
              @include large {
                font-size: calc(10rem - 1.5rem);
              }
              @media (min-width: 1100px) {
                font-size: calc(10rem - 1rem);
              }
              @media (min-width: 1200px) {
                font-size: calc(10rem - .5rem);
              }
              @media (min-width: 1300px) {
                font-size: 10rem;
              }
            }
          }
        }
        .campaign-heading-wrapper__line-one {
          align-self: center;
          @include small {
            align-self: flex-start;

          }
        }
        .campaign-heading-wrapper__line-two {
          align-self: center;
          margin-top: 0.5rem;
          @include small {
            align-self: flex-end;
            margin: 0;
          }
        }
        .campaign-heading-wrapper__line-two {
          .campaign-heading {
            padding-left: 2rem;
            @include small {
              padding-left:4rem;
            }
            @include medium {
              padding-left:5rem;
            }
            @include large {
              padding-left:6rem;
            }
            @media (min-width: 1100px) {
              padding-left: 7rem;
            }
            @media (min-width: 1200px) {
              padding-left: 8rem;
            }
            @media (min-width: 1300px) {
              padding-left: 9rem;
            }
          }
        }
      }
      .campaign-content__block-one {
        display: inline-block;
        margin: 2rem 0;
        p {
          font-family: "Crimson Pro", serif;
          font-size: 2rem;
          font-weight: 700;
          line-height: 1;
          margin: 0;
          padding: 0;
          color: $brand-main;
          text-align: center;
          @include large {
            text-align: right;
          }
          @media (min-width: 1400px) {
            font-size: 2.5rem;
          }
        }
        span {
          font-style: italic;
        }
      }
      .campaign-content__block-two {
        display: inline-block;
        position: relative;
        p {
          font-family: "Crimson Pro", serif;
          font-size: 1.3125rem;
          font-weight: 500;
          line-height: 1.2;
          color: #fff;
          padding: 0;
          margin: 0;
          text-align: center;
          @include large {
            text-align: right;
          }
        }
        &:before {
          content: '';
          background-color: $white;
          height: 1px;
          width: 100%;
          position: absolute;
          top: -.5rem;
        }
        &:after {
          content: '';
          background-color: $white;
          height: 1px;
          width: 100%;
          position: absolute;
          bottom: -.5rem;
        }

      }
      .campaign-content__block-three {
        display: inline-block;
        position: relative;
        margin: 2rem 0;
        p {
          font-family: "Crimson Pro", serif;
          font-size: 1.75rem;
          font-weight: 500;
          line-height: 1.2;
          color: $brand-main;
          padding: 0;
          margin: 0;
          text-align: center;
          @include large {
            text-align: right;
          }
        }
      }
    }
    .image-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;

      picture {
        width:100%;
        height:100%;

        img {
          width:100%;
          height:100%;
          object-fit:cover;
        }
      }
    }

  }
}

//End Hero Section
