.person-content-section {
  position: relative;
  margin-top: 2rem;
  .person-content-section_outer-wrapper {
    padding: 0 0 2rem;
    background-color: $white;
    box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
    float: left;
    margin-bottom: 3rem;
    .person-content-section_inner-wrapper {
      display: flex;
      flex-direction: column;
      padding: 0 2rem;

      @include medium {
        flex-direction: row;
      }
      .person-content-section_content-container {
        &.no-image {
          h1, h2, h3, h4, h5, h6, p {
            padding: 0;
          }
          ul, ol, dl {
            padding: 0 0 0 2rem;
          }
        }
      }
    }
  }
  .person-sidebar-section_content-wrapper {
    position: relative;
    float: right;
    padding: 0 0 3rem 0;
    width: 100%;
    @include large {
      width: 30%;
    }
    .person-sidebar_content {
      .block {
        background-color: $white;
        box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
        margin-bottom: 3rem;
      }
      .block-webform {
        header {
          background-color: $brand-main;
          margin-bottom: .5rem;
          h2 {
            color: $white;
            padding: 1rem 2rem;
            margin: 0;
          }
        }
        .webform-client-form{
          display: block;
          padding: 0 2rem 1rem;
          @include large {
            padding: 0 2rem 1rem;
          }
          .input-wrapper {
            width: 100%;
          }
          button {
            display: block;
            margin:  .5rem auto 0 !important;
            font-size: 1.25rem;
            text-transform: uppercase;
          }
          .form-actions {
            margin-top: .5rem;
            input[type="submit"]{
              display: block;
              margin:  .8rem auto 0 !important;
              font-size: 1.25rem;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}
