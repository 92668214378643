.footer{
  background-color: $flat-blue;
  padding: 0;
  .footer-map-container{
    #map{
      height: 150px;
    }
  }
  p, li, li a{
    font-family: $font-stack;
    font-weight: 300;
    font-size: .75rem;
  }
  .footer-container{
    .info-container {
      display: block;
      margin: 0 auto;
      padding: 1rem 0;
      div {
        display: block;
        float: none;
        text-align: center;
        margin-bottom: .5rem;

        @include medium{
          float: left;

          //margin-bottom: 0rem;
        }
        p {
          color: $white;
        }
        p.address, p.phone, p.email, p.social   {
          margin: 0;
          font-size: 1rem;
          a{
            color: $white;
            text-decoration: none;
          }

        }
        p.address:nth-child(3){
          margin-bottom: .5rem;
        }

        p.phone:nth-child(2){
          margin-bottom: .5rem;
        }


        p.email a{
          text-decoration: none;
          color: $white;
        }
        p.small-caps {
          font-size: 1rem;
          margin: 0;
          padding-bottom: 0;
          @include small {
            padding-bottom: .25rem;
          }
        }
        p.social{
          font-size: 1.5rem;
          display: inline-block;
          margin-top: -2px;
          a{
            color: $white;
          }
          i{
            display: inline-block;
            margin-left: .25rem;
          }
        }

      }
      #map{
        height: 150px;
      }
    }
  }
  .disclaimer-container{
    text-align: center;
    background-color: $dk-grey;
    padding: .5rem 0 1rem;
    p {
      color: $md-grey;
      font-size: .75rem;
    }
    p:nth-of-type(1) {
      margin-bottom: 0;
    }
  }
}
