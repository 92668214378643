.article-hero-section{
  position: relative;
  z-index: 0;
  .main-hero{
    position: relative;
    .caption-wrapper{
      position: absolute;
      height: 100%;
      .caption{
        box-shadow: 0 2px 5px rgba(0,0,0,0.26);
        padding: .5rem 0;
        margin: auto auto -2rem;
        background-color: $flat-blue;
        display: none;
        @include small{
          display: flex;
          margin: auto 0 auto auto;
        }
        h1{
          font-size: 2rem;
          line-height: 1;
          color: $white;
          @include medium{
            font-size: 2.5rem;
          }
          @include large{
            font-size: 3rem;
          }
          .no-break {
            white-space: nowrap;
            sup {
              font-size: 1rem;
              vertical-align: super;
              font-weight: 200;
            }
          }
        }
        p.subhead{
          color: $white;
          font-size: 1.25rem;
          @include large{
            font-size: 1rem;
          }
        }
      }
    }
  }
}
.article-content-section {
  position: relative;
  margin-top: 2rem;
  &.follows-hero {
    margin-top: -3rem;
    @include medium {
      margin-top: -5rem;
    }
    @include large {
      margin-top: -8rem;
    }
  }
  .article-content-section_content-wrapper {
    padding: 0 0 2rem;
    background-color: $white;
    box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
    float: left;
    margin-bottom: 3rem;
  }
  .article-sidebar-section_content-wrapper {
    position: relative;
    float: right;
    padding: 0 0 3rem 0;
    width: 100%;
    @include large {
      width: 30%;
    }
    .article-sidebar_content {
      .block {
        background-color: $white;
        box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
        margin-bottom: 3rem;
      }
      .block-webform {
        header {
          background-color: $brand-main;
          margin-bottom: .5rem;
          h2 {
            color: $white;
            padding: 1rem 2rem;
            margin: 0;
          }
        }
        .webform-client-form{
          display: block;
          padding: 0 2rem 1rem;
          @include large {
            padding: 0 2rem 1rem;
          }
          .input-wrapper {
            width: 100%;
          }
          button {
            display: block;
            margin:  .5rem auto 0 !important;
            font-size: 1.25rem;
            text-transform: uppercase;
          }
          .form-actions {
            margin-top: .5rem;
            input[type="submit"]{
              display: block;
              margin:  .8rem auto 0 !important;
              font-size: 1.25rem;
              text-transform: uppercase;
            }
          }
        }
      }

      #block-views-blog-tags-block {
        padding: 1rem 0 2rem;
        header {
          margin-bottom: 1.25rem;
          h2 {
            font-size: 1.5rem;
            color: $dk-grey;
            margin: .75rem 0;
            position: relative;
            font-weight: 700;
            letter-spacing: 0;

            &:after {
              background-color: $brand-main;
              content: '';
              height: 2px;
              position: absolute;
              width: calc(100% - 4rem);
              bottom: -.5rem;
              left: 2rem;
            }
          }

        }
        .view-blog-tags {
          padding: 0 2rem;
          .view-content {
            display: flex;
            flex-wrap: wrap;
            .views-row {
              background-color: $brand-main;
              border: solid 1px $brand-main;
              border-radius: 1rem;
              color: $white;
              display: inline-block;
              font-size: 1rem;
              text-align: center;
              margin: 0 .25rem .5rem;
              padding: .25rem 1rem;
              text-decoration: none;
              transition: background-color .25s ease-in-out, color .25s ease-in-out;
              &:nth-of-type(1) {
                margin-left: 0;
              }
              &:last-of-type {
                margin-right: 0;
              }
              a {
                color: $white;
                font-size: 1rem;
                text-align: center;
                text-decoration: none;

              }
              &:hover,
              &:active {
                background-color: $white;
                a {
                  color: $brand-main;
                }
              }
            }
          }
        }
      }
    }
  }
}

.view-article-listing {
  width: 100%;
  padding: 2rem 3.5% 3rem;
  .view-article-listing-content-wrapper {
    padding: 2rem 0 1rem;
    background-color: $white;
    box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
    float: left;
    margin-bottom: 3rem;

    .views-exposed-widgets {
      padding: 0 2rem;
      margin-bottom: 0;
      position: relative;
      &:after {
        background-color: $brand-main;
        content: '';
        height: 2px;
        position: absolute;
        left: 2rem;
        width: calc(100% - 4rem);
        bottom: -.5rem;
        visibility: visible;
      }

      .views-exposed-widget {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        float: none;
        padding: 0;

        label {
          margin: 0 .25rem .5rem 0;
        }

        .form-item{
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          padding: 0;

          .form-type-bef-link {
            background-color: $brand-main;
            border: solid 1px $brand-main;
            border-radius: 1rem;
            color: $white;
            display: inline-block;
            font-size: 1rem;
            text-align: center;
            margin: 0 .25rem .5rem;
            padding: .25rem 1rem;
            text-decoration: none;
            transition: background-color .25s ease-in-out, color .25s ease-in-out;
            &:nth-of-type(1) {
              margin-left: 0;
            }
            &:last-of-type {
              margin-right: 0;
            }
            a {
              color: $white;
              font-size: 1rem;
              text-align: center;
              text-decoration: none;

            }

            &:hover,
            &:active {
              background-color: $white;
              a {
                color: $brand-main;
              }
            }
          }
        }
      }

    }

    .view-content {
      .views-row {
        position: relative;
        margin-bottom: 3rem;

        &:after {
          background-color: $brand-main;
          content: '';
          height: 2px;
          position: absolute;
          left: 2rem;
          width: calc(100% - 4rem);
          bottom: -1.5rem;
        }

        &:last-of-type {
          padding-bottom: 2rem;
          &:after {
            content: none;
          }
        }
      }
    }
  }

  .view-article-listing-sidebar-wrapper {
    background-color: #fff;
    box-shadow: 0px 3px 4.7px 0.3px rgba(0,0,0,0.24);
    position: relative;
    float: right;
    padding: 0 0 3rem 0;
    width: 100%;
    @include large {
      width: 30%;
    }
    .block-webform {
      header {
        background-color: $brand-main;
        margin-bottom: .5rem;
        h2 {
          color: $white;
          padding: 1rem 2rem;
          margin: 0;
        }
      }
      .webform-client-form{
        display: block;
        padding: 0 2rem 1rem;
        @include large {
          padding: 0 2rem 1rem;
        }
        .input-wrapper {
          width: 100%;
        }
        button {
          display: block;
          margin:  .5rem auto 0 !important;
          font-size: 1.25rem;
          text-transform: uppercase;
        }
        .form-actions {
          margin-top: .5rem;
          input[type="submit"]{
            display: block;
            margin:  .8rem auto 0 !important;
            font-size: 1.25rem;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

.article-teaser-wrapper {
  display: flex;
  flex-direction: row;
  .article-teaser-image-wrapper {
    max-width: 300px;
    margin: 0;
    @include medium {
      margin: 0 0 0 2rem;
    }
  }
  .article-teaser-content-wrapper {
    margin: 0;
    @include medium {
      margin: 0 2rem 0 0;
    }
  }
}
