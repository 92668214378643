.intro-section {
  position: relative;
  margin-top: -10vh;
  .container {
    box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
    background-color: $white;
    .how-we-help-section-header{
      background-color: $brand-main;
    }

    .text-wrapper {
      background-color: $white;
      //padding: 3rem 0;
      float: left;
      h1 {
        margin-top: 3rem;
      }
      p:last-child {
        margin-bottom: 3rem;
      }
    }
    .image-wrapper {
      background: url('../img/mtg-2.jpg') no-repeat center;
      background-size: cover;
      float: left;
      @include medium{
        display: none;
      }


    }
  }
}
